import React, { memo, useEffect, useRef, useState } from "react";
import EditorJS, { OutputData } from "@editorjs/editorjs";
import { EDITOR_TOOLS } from "./EditorTools";

//props
type Props = {
    data?: OutputData | any;
    onChange(val: OutputData): void;
    holder: string;
  };


function EditorBlock({ onChange, holder, data }: Props) {
  
    //add a reference to editor
  const ref = useRef<EditorJS>();

     //initialize editorjs
    //initialize editor if we don't have a reference
    if (!ref.current) {
      const editor = new EditorJS({
        holder: holder,
        tools: EDITOR_TOOLS,
        data,
        readOnly: false,
        async onChange(api, event) {
          const data = await api.saver.save();
          onChange(data);
          
        },
      });
      ref.current = editor;
    }

  return <div id={holder} />;
}


export default memo(EditorBlock);