import { showNotification } from '@mantine/notifications';
import axios from 'axios';
import { PATH } from '../constants/paths';
import { checkJwtExpiration, createBlockEditorJS, generateObjectEditorJs, getAnswers, getItem, truncateString } from '../utils/utilsApi';
import { addDemand } from './demands.api';
import i18n from '../i18n';

export async function getDataResponseId(responseId: string) {
    checkJwtExpiration();
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    const hour = date.getHours();
    const minute = date.getMinutes();
    const second = date.getSeconds();


    const getDataTypeform = async () => {
        await axios.get(PATH.API + "typeformdata/" + responseId, {
            headers: {
                Authorization: 'Bearer ' + getItem("token")
            }
        })
            .then((res) => {
                return res.data;
            })
            .then((extractDataTypeFormsTypeForm) => {
                const answersBlock: any = getAnswers(extractDataTypeFormsTypeForm);
                return createBlockEditorJS(answersBlock);
            })
            .then(datasTypeForm => {
                const truncatedText = truncateString(datasTypeForm.answer4.label, 58);
                addDemand({
                    title: truncatedText,
                    name: datasTypeForm.block,
                    category: `${datasTypeForm.answer1.label}/${datasTypeForm.answer2.label}/${datasTypeForm.answer3.label}`,
                    priority: 'low',
                    endDate: `${year}-${month}-${day} ${hour}:${minute}:${second}`
                }).catch((e) => {
                    console.log(e)
                });
                return Promise.resolve(true);
            }).catch(() => {
                showNotification({
                    title: i18n.t("TitleAddDemandTypeform"),
                    message: i18n.t("MessageAddDemandTypeform"),
                    color: 'red',
                });
            })
    }
    getDataTypeform();
}

