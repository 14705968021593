import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const translationEn = {
    // PAGE DASHBOARD
    myDemands: "My requests",
    searchRequest: "Search for a request",
    awaitProcess: "Awaiting processing",
    requestProcessed: "Request processed",
    newRequest: "New request",
    labelName: "Name",
    labelCategory: "Category",
    placeHolderDescDemandInput: "Describe your request: support, intended purpose",
    profile: "Profile",
    descriptionOfYourRequest: "Description of your request",
    cancel: "Cancel",
    editDemand: "Edit demand",
    priority: "Priority",
    delete: "Delete",
    save: "Save",
    submit: "Submit",
    create: "Create",
    logout: "Logout",
    youHaveEditedYourRequest: "You have edited your request",
    youHaveAddedRequest: "You have added a request",
    youHaveDeletedTheRequestNumber: "You have deleted the request number",
    listOfRequests: "List of requests",
    addRequest: "Add Request",
    //Status
    Status: "Status",
    validate: "Validate",
    awaitingTreatment: "Awaiting treatment",
    awaitingCustomerValidation: "Awaiting customer validation",
    open: "Open",
    resolved: "Resolved",
    closed: "Closed",
    new: "New",
    inProcess: "In process",
    archived: "Archived",
    read: "read",
    //Propriéte 
    Low: "Low",
    Medium: "Medium",
    High: "High",
    // PAGE ACCOUNT
    Account: "Account",
    personalInformation: "Personal information",
    yourCompany: "Your company",
    parameters: "Parameters",
    encloseTheChanges: "Enclose the changes",
    user: "User",
    edit: "Edit",
    ManageAccount: "Managing your account ",
    //PAGE LOGIN
    createAccount: "Create Account",
    alreadyMemberAtUnitix: "Already a member at Unitix",
    logIn: "Log in",
    notYetMemberOfOurService: "Not yet a member of our service",
    stayingConnected: "Staying connected",
    forgotYourPassword: "Forgot your password",
    logOnTo: "Log on to",
    Register: "Register",
    Password: "Password",
    //PAGE ACCOUNT
    //INF0RMATION PERSONNELLE
    INFORMATIONPERSONNELLES: "PERSONAL INFORMATION",
    Prénom: "Firstname",
    AdresseMail: "E-mail",
    Name: "Name",
    LastName: "Last name",
    Adresse: "Address",
    Ville: "City",
    Pays: "Country",
    NumeroPortable: "Phone Number",
    NumeroTelephone: "Domicile Number",
    ComplémentAdresse: "Address complement",
    CodePostal: "Postal code",
    // INFORMATION VOTRE ENTRERPRISE
    INFORMATIONDEVOTREENTREPRISE: "INFORMATION ABOUT YOUR COMPANY",
    NomEntreprise: "Company name",
    NomRueDuSiége: "Seat street name",
    ManageCompany: "Managing your company",
    ManageGroup: "Manage group",
    MailEntreprise: "Company email",
    NumeroSiret: "Number of siret",
    PhoneNumberEntreprise: "Company phone number",
    AdresseEntreprise: "Company address",
    ComplémentAdresseEntreprise: "Additional information Company address",
    CodePostaleEntreprise: "Postal Code Company",
    VilleEntreprise: "Company City",
    PaysEntreprise: "Company Country",
    ShowMembers: "Show members",
    AddEmployee: "Invite employee",
    InfoCompany: "Info company",
    Employee: "Employee",
    // Demands 
    DateRequest: "Ticket date",
    Actions: "Actions",
    Show: "Show",
    Desc: "Description",
    //Headers
    ContactUs: "Contact us",
    Cancel: "Cancel",
    Company: "Company",
    //Notif Message
    TitleNoMembers: "Dont have members",
    MessageNoMembers: "No members in the company",
    TitleGroupData: "Error get all groups",
    MessageGroupData: "Error get all groups contact support admin",
    TitleUpdateCompany: "Company updated",
    MessageUpdateCompany: "Company details have been edited",
    TitleAddGroup: "Add group",
    MessageAddGroup: "Groupe added",
    TitleUpdateGroupUser: "Update group user",
    MessageUpdateGroupUser: "Group user updated",
    TitleAddUserInGroup: "User Added in group",
    MessageAddUserInGroup: "You have successfully added the group to the user",
    TitleUpdateGroup: "Group updated",
    MessageUpdateGroup: "You have successfully updated the group",
    TitleDeleteGroup: "Group delete",
    MessageDeleteGroup: "Group delete",
    TitleInvitation: "Invitation send",
    MessageInvitation: "Invitation send",
    NoAuthorize: "Not authorized",
    MessageNoAuthorize: "You are not authorized contact support",
    AddDemand: "Demand added",
    MessageAddDemand: "Your request has been added",
    EditDemand: "Demand edited",
    MessageEditDemand: "Your request has been edited",
    DeleteDemand: "Demand deleted",
    MessageDeleteDemand: "Your request has been deleted",
    ChangeStatus: "Status changed",
    MessageStatusChange: "Your request has been changed",
    TitleAddDemandTypeform: "Error adding request",
    MessageAddDemandTypeform: "Sorry, your request could not be completed, please try again",
    TitleLoginNotif: "Invalid credentiels",
    MessageLoginNotif: "Please check your credentials",
    TitleSignUpNotif: "Create account",
    MessageSignUpNotif: "Wait 2 second for create account",
    TitleErrorSignUpNotif: "Error Sign Up",
    MessageErrorSignUpNotif: "An error occurred while creating the account",
    TitleUpdateProfile: "Account edited",
    MessageUpdateProfile: "Wait 2 second for edit account",
    TitleErrorUpdateProfile: "Error update profile",
    MessageErrorUpdateProfile: "An error occurred when editing the account",
    TitleErrorGetProfile: "Error get profile",
    MessageErrorGetProfile: "An error to get profile",
    TitleForgotPassNotif: "Account not exists",
    MessageForgotPassNotif: "Check your email",
    TitlePassChanged: "Password changed",
    MessagePassChanged: "Now connect with new password",
    TitleCodeChange: "Code not exists",
    MessageCheckCode: "Check your code",
    Members: "Members",
    TitleModalFeedBack: "Make a return of a problem or an improvement",
    Comment: "Comment",
    TitleNotifFeedBack: "Feedback sent",
    MessageNotifFeedBack: "Your feedback has been sent",
    InvitedList: "Invited list",
    Group: "Group",
    ShowDemands: "Show demands",
    EditDemands: "Edit demands",
    DeleteDemands: "Delete demands",
    AddGroup: "Add group",
    Add: "Add",
    TextInvitationEmploye: "Invite employe with mail",
    SendInvitation: "Send invitation",
    ChooseColor: "Choose color",
    Update: "Update",
    TextInvitedEmployee: "Invited employee",
    Of: "Of",
    SendMailPartner: "A mail has been sent to",
    MessageSendMailPartner: "A mail has been sent to"
}


const translationFr = {
    // PAGE DASHBOARD
    myDemands: "Mes demandes",
    searchRequest: "Rechercher une demande",
    awaitProcess: "En attente de traitement",
    requestProcessed: "Demande traitée",
    newRequest: "Nouvelle demande",
    labelName: "Nom",
    TitleAddGroup: "Ajouter un groupe",
    labelCategory: "Domaine",
    Members: "Membres",
    placeHolderDescDemandInput: "Décrivez votre demande: support, objectif visé",
    profile: "Profil",
    descriptionOfYourRequest: "Description de la requêtes",
    cancel: "Fermer",
    editDemand: "Modifier votre demande",
    priority: "Propriéte",
    delete: "Supprimer",
    submit: "Envoyer",
    save: "Enregistrer",
    create: "Crée",
    logout: "Déconnexion",
    youHaveEditedYourRequest: "Vous avez édité votre demande",
    youHaveAddedRequest: "Vous avez ajouté une demande",
    youHaveDeletedTheRequestNumber: "Vous avez supprimé la demande num ",
    listOfRequests: "Liste de vos demandes",
    addRequest: "Ajouter une demande",
    // PAGE ACCOUNT
    Account: "Compte",
    personalInformation: "Information personnel",
    yourCompany: "Votre entreprise",
    parameters: "Parametres",
    encloseTheChanges: "Enrengistrer les modifications",
    user: "Utilisateur",
    edit: "Editer",
    ManageAccount: "Gestion de votre compte",
    Adresse: "Adresse",
    // Status 
    Status: "Statut",
    validate: "Validé",
    awaitingTreatment: "En attente de traitement",
    awaitingCustomerValidation: "En attente de validation client",
    inProcess: "En cours de traitement",
    open: "Ouvert",
    resolved: "Résolu",
    closed: "Cloturé",
    new: "Nouveau",
    archived: "Archivé",
    read: "lu",
    //Propriéte 
    Low: "Faible",
    Medium: "Moyenne",
    High: "Haute",
    // PAGE LOGIN
    createAccount: "Créer mon compte",
    alreadyMemberAtUnitix: "Déjà membre chez Unitix",
    logIn: "Se connecter",
    notYetMemberOfOurService: "Pas encore membre de notre service",
    stayingConnected: "Rester connecté",
    forgotYourPassword: "Mot de passe oublié",
    logOnTo: "Connectez-vous",
    Register: "Inscrivez-vous",
    Password: "Mot de passe",
    //PAGE ACCOUNT
    //INFORMATION PERSONNELLE
    Prénom: "Prénom",
    AdresseMail: "Adresse email",
    Name: "Nom",
    LastName: "Nom",
    Adresse: "Adresse",
    Ville: "Ville",
    Pays: "Pays",
    NumeroPortable: "N° de portable",
    NumeroTelephone: "N° de téléphone",
    ComplémentAdresse: "Complément d'adresse",
    CodePostal: "Code postal",

    //INFORMATION ENTREPRISE
    NomEntreprise: "Nom de votre entreprise",
    NomRueDuSiége: "Nom de rue du siège",
    MailEntreprise: "Adresse mail entreprise",
    NumeroSiret: "N° de Siret",
    PhoneNumberEntreprise: "N° de téléphone entreprise",
    AdresseEntreprise: "Adresse entreprise",
    ComplémentAdresseEntreprise: "Complément Adresse Entreprise",
    CodePostaleEntreprise: "Code postale entreprise",
    VilleEntreprise: "Ville entreprise",
    PaysEntreprise: "pays de l'entreprise",
    ManageCompany: "Gestion de votre compagnie",
    ManageGroup: "Gérer les groupe",
    ShowMembers: "Voir les membres",
    AddEmployee: "Inviter un employé",
    InfoCompany: "Information",
    Employee: "Employés",
    // Demands 
    DateRequest: "Date du ticket",
    Actions: "Actions",
    Show: "Voir",
    Desc: "Description",
    //Headers 
    ContactUs: "Nous contacter",
    Cancel: "Annuler",
    Company: "Compagnie",
    // Notifications de messages
    TitleNoMembers: "Aucun membre",
    MessageNoMembers: "Aucun membre dans l'entreprise",
    TitleGroupData: "Erreur lors de la récupération de tous les groupes",
    MessageGroupData: "Erreur lors de la récupération de tous les groupes, contactez l'administrateur pour obtenir de l'aide",
    TitleUpdateCompany: "Entreprise mise à jour",
    MessageUpdateCompany: "Les détails de l'entreprise ont été modifiés",
    TitleAddGroup: "Ajouter un groupe",
    MessageAddGroup: "Le groupe a été ajouté",
    TitleUpdateGroupUser: "Mise à jour de l'utilisateur du groupe",
    MessageUpdateGroupUser: "L'utilisateur du groupe a été mis à jour",
    TitleAddUserInGroup: "Utilisateur ajouté au groupe",
    MessageAddUserInGroup: "Vous avez ajouté avec succès l'utilisateur au groupe",
    TitleUpdateGroup: "Groupe mis à jour",
    MessageUpdateGroup: "Vous avez mis à jour le groupe avec succès",
    TitleDeleteGroup: "Supprimer le groupe",
    MessageDeleteGroup: "Supprimer le groupe",
    TitleInvitation: "Invitation envoyée",
    MessageInvitation: "Invitation envoyée",
    NoAuthorize: "Non autorisé",
    MessageNoAuthorize: "Vous n'êtes pas autorisé, contactez le support",
    AddDemand: "Demande ajoutée",
    MessageAddDemand: "Votre demande a été ajoutée",
    EditDemand: "Demande modifiée",
    MessageEditDemand: "Votre demande a été modifiée",
    DeleteDemand: "Demande supprimée",
    MessageDeleteDemand: "Votre demande a été supprimée",
    ChangeStatus: "Statut modifié",
    MessageStatusChange: "Votre demande a été modifiée",
    TitleAddDemandTypeform: "Erreur lors de l'ajout de la demande",
    MessageAddDemandTypeform: "Désolé, votre demande n'a pas pu être complétée, veuillez réessayer",
    TitleLoginNotif: "Identifiants invalides",
    MessageLoginNotif: "Veuillez vérifier vos identifiants",
    TitleSignUpNotif: "Créer un compte",
    MessageSignUpNotif: "Attendez 2 secondes pour créer un compte",
    TitleErrorSignUpNotif: "Erreur lors de la création du compte",
    MessageErrorSignUpNotif: "Une erreur est survenue lors de la création du compte",
    TitleUpdateProfile: "Compte modifié",
    MessageUpdateProfile: "Attendez 2 secondes pour modifier le compte",
    TitleErrorUpdateProfile: "Erreur lors de la modification du profil",
    MessageErrorUpdateProfile: "Une erreur est survenue lors de la modification du compte",
    TitleErrorGetProfile: "Erreur lors de la récupération du profil",
    MessageErrorGetProfile: "Une erreur est survenue lors de la récupération du profil",
    TitleForgotPassNotif: "Compte inexistant",
    MessageForgotPassNotif: "Vérifiez votre adresse e-mail",
    TitlePassChanged: "Mot de passe modifié",
    MessagePassChanged: "Connectez-vous maintenant avec le nouveau mot de passe",
    TitleCodeChange: "Code inexistant",
    MessageCheckCode: "Vérifiez votre code",
    TitleModalFeedBack: "Faire un retour d'un problème ou d'une amélioration",
    Comment: "Commentaire",
    TitleNotifFeedBack: "Retour envoyé",
    MessageNotifFeedBack: "Votre retour a été envoyé",
    InvitedList: "Liste des invités",
    Group: "Groupe",
    ShowDemands: "Voir les demandes",
    EditDemands: "Modifier les demandes",
    DeleteDemands: "Supprimer les demandes",
    AddGroup: "Ajouter un groupe",
    Add: "Ajouter",
    TextInvitationEmploye: "Inviter un employé",
    ChooseGroup: "Choisir un groupe",
    SendInvitation: "Envoyer l'invitation",
    ChooseColor: "Choisir la couleur principale",
    Update: "Mettre à jour",
    TextInvitedEmployee: "Employés invités",
    Of: "De",
    SendMailPartner: "Mail envoyer à",
    MessageSendMailPartner: "Un mail a été envoyé à"
}

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: { translation: translationEn },
            fr: { translation: translationFr }
        },
        lng: "fr",
        fallbackLng: "fr",
        interpolation: { escapeValue: false }
    });

export default i18n;