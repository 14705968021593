import {
    TextInput,
    PasswordInput,
    Anchor,
    Paper,
    Container,
    Group,
    Button,
} from '@mantine/core';
import { login } from '../../apis/user.api';
import { useForm } from '@mantine/form';
export function AuthenticationTitle() {
    const form = useForm({
        initialValues: { username: '', password: '' },

        // functions will be used to validate values at corresponding key
        validate: {
            username: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
            password: (value) => (/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(value) ? null : `
                Has minimum 8 characters in length -
                One letter uppercase -
                One special character
          `),
        },

    });
    
    return (
        <Container size={420} my={40}>


            <Paper withBorder shadow="md" p={30} mt={30} radius="md">
                <form onSubmit={
                    form.onSubmit((values) => {
                        const username = values.username
                        const password = values.password
                        login({ username, password });
                    })
                }>
                    <TextInput label="Email" placeholder="you@unitix.io" required
                        {...form.getInputProps('username')}
                    />
                    <PasswordInput label="Password" placeholder="Your password" required mt="md"
                        {...form.getInputProps('password')}
                    />
                    <Group position="apart" mt="md">
                        <Anchor<'a'> href="/forgotPassword" size="sm">
                            Forgot password?
                        </Anchor>
                    </Group>
                    <Button fullWidth mt="xl" sx={(theme) => ({
                        backgroundColor: theme.colors.orange[6], '&:hover': {
                            backgroundColor: theme.colors.orange[5],
                        },
                    })} type="submit">
                        Sign in
                    </Button>
                </form>

            </Paper>
        </Container>
    );
}