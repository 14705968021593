import React, { useContext, useState } from "react"
import { Anchor, Text, Title } from "@mantine/core"
import { Navigate, useNavigate } from "react-router-dom"
import { AuthenticationTitle } from "../../components/Login/LoginForm"
import { SignUpForm } from "../../components/SignUp/SignUpForm"
import Header from "../../layouts/Header"
import { ForgotPasswordForm } from "../../components/ForgotPassword/ForgotPasswordForm"


const ForgotPassword = () => {
    const [isFormLogin, seTisFormLogin] = useState(true);


    return (
        <div className="container" style={{
        }}>
            {<Header />}
            <div className="min-vh-100 row">
                <Title
                    align="center"
                    size="2.5em"

                >
                    Forgot Password
                </Title>
                <Text color="dimmed" size="sm" align="center" mt={5}>
                </Text>
                {<ForgotPasswordForm />}
            </div>
        </div>
    )
}

export default ForgotPassword
