import { showNotification } from '@mantine/notifications';
import axios from 'axios';
import { PATH } from '../constants/paths'
import i18n from '../i18n';
import { checkJwtExpiration, getItem } from '../utils/utilsApi';
import { notifications } from '@mantine/notifications';


export async function demands() {
    checkJwtExpiration();
    const demands = await axios
        .get(PATH.API + "demands", {
            headers: {
                Authorization: 'Bearer ' + getItem("token")
            }
        })
        .catch((e) => {
            showNotification({
                title: i18n.t("NoAuthorize"),
                message: i18n.t("MessageNoAuthorize"),
                color: 'red',
            });
        });
    return demands;
}

export function addDemand(demand: any) {
    checkJwtExpiration();
    const response = axios
        .post(PATH.API + "demand/add", demand, {
            headers: {
                Authorization: 'Bearer ' + getItem("token")
            }
        }).then(() => {
            showNotification({
                title: i18n.t("AddDemand"),
                message: i18n.t("MessageAddDemand"),
                color: 'green',
            });
        })

    return response;
}

export function editDemand(demandUpdate: any) {
    checkJwtExpiration();
    const response = axios
        .post(PATH.API + "demand/update", demandUpdate, {
            headers: {
                Authorization: 'Bearer ' + getItem("token")
            }
        }).then(() => {
            notifications.show({
                title: i18n.t("EditDemand"),
                message: i18n.t("MessageEditDemand"),
                color: 'green',
            });
        })
    return response;
}

export function deleteDemand(demandId: number) {
    checkJwtExpiration();
    const response = axios
        .delete(PATH.API + "demand/delete/" + demandId, {
            headers: {
                Authorization: 'Bearer ' + getItem("token")
            }
        }).then(() => {
            notifications.show({
                title: i18n.t("DeleteDemand"),
                message: i18n.t("MessageDeleteDemand"),
                color: 'green',
            });
        })
    return response;
}

export function changeStatus(status: string, demandId: number) {
    checkJwtExpiration();
    const response = axios
        .get(PATH.API + "demand/" + demandId + "/" + status, {
            headers: {
                Authorization: 'Bearer ' + getItem("token")
            }
        }).then(() => {
            showNotification({
                title: i18n.t("ChangeStatus"),
                message: i18n.t("MessageStatusChange"),
                color: 'green',
            });
        })
    return response;
}


