import jwtDecode from 'jwt-decode';
import axios from 'axios';
import { showNotification } from '@mantine/notifications';
import { PATH } from '../constants/paths'
import { addItem, checkJwtExpiration, getItem, removeItem } from '../utils/utilsApi';
import i18n from '../i18n';


export async function getUsersCompany() {
    checkJwtExpiration();

    const getUsersCompany = await axios
        .get(PATH.API + "company/users", {
            headers: {
                Authorization: 'Bearer ' + getItem("token")
            }
        })
        .catch((e) => {
            showNotification({
                title: i18n.t("NoMembers"),
                message: i18n.t("MessageNoMembers"),
                color: 'red',
            });
        });
    return getUsersCompany;
}

export async function getAllGroupData() {
    checkJwtExpiration();

    const getUsersCompany = await axios
        .get(PATH.API + "group/allData", {
            headers: {
                Authorization: 'Bearer ' + getItem("token")
            }
        })
        .catch((e) => {
            showNotification({
                title: i18n.t("TitleGroupData"),
                message: i18n.t("MessageGroupData"),
                color: 'red',
            });
        });
    return getUsersCompany;
}



export async function updateCompany(company: any) {
    checkJwtExpiration();

    const response = await axios
        .post(PATH.API + "company/update", company, {
            headers: {
                Authorization: 'Bearer ' + getItem("token")
            }
        })
        .then(() => {
            showNotification({
                title: i18n.t("TitleUpdateCompany"),
                message: i18n.t("MessageUpdateCompany"),
                color: 'green',
            });
        })

    return response;
}

export async function addGroupInCompany(group: any) {
    checkJwtExpiration();

    const response = await axios
        .post(PATH.API + "group/add", group, {
            headers: {
                Authorization: 'Bearer ' + getItem("token")
            }
        })
        .then(() => {
            showNotification({
                title: i18n.t("TitleAddGroup"),
                message: i18n.t("MessageAddGroup"),
                color: 'green',
            });
        })

    return response;
}


export async function updateGroupOfUser(groupName: any, userId: any) {
    checkJwtExpiration();

    const response = await axios
        .get(PATH.API + "group/updateUser/" + groupName + "/" + userId, {
            headers: {
                Authorization: 'Bearer ' + getItem("token")
            }
        })
        .then(() => {
            showNotification({
                title: i18n.t("TitleUpdateGroupUser"),
                message: i18n.t("MessageUpdateGroupUser"),
                color: 'green',
            });
        })

    return response;
}

export async function addGroupInUser(groupName: any, userId: any) {
    checkJwtExpiration();

    const response = await axios
        .get(PATH.API + "group/addUser/" + groupName + "/" + userId, {
            headers: {
                Authorization: 'Bearer ' + getItem("token")
            }
        })
        .then(() => {
            showNotification({
                title: i18n.t("TitleAddUserInGroup"),
                message: i18n.t("MessageAddUserInGroup"),
                color: 'green',
            });
        })

    return response;
}

export async function updateGroup(groupId: any, group: any) {
    checkJwtExpiration();

    const response = await axios
        .post(PATH.API + "group/update/" + groupId, group, {
            headers: {
                Authorization: 'Bearer ' + getItem("token")
            }
        })
        .then(() => {
            showNotification({
                title: i18n.t("TitleUpdateGroup"),
                message: i18n.t("MessageUpdateGroup"),
                color: 'green',
            });
        })

    return response;
}

export async function getAllGroup() {
    checkJwtExpiration();

    const response = await axios
        .get(PATH.API + "group/all", {
            headers: {
                Authorization: 'Bearer ' + getItem("token")
            }
        })

    return response;
}

export async function deleteGroup(idGroup: Number) {
    checkJwtExpiration();

    const response = await axios
        .delete(PATH.API + "group/delete/" + idGroup, {
            headers: {
                Authorization: 'Bearer ' + getItem("token")
            }
        }).then(() => {
            showNotification({
                title: i18n.t("TitleDeleteGroup"),
                message: i18n.t("MessageDeleteGroup"),
                color: 'green',
            });
        })

    return response;
}

export async function inviteEmploye(guestMail: any, groupName: any) {
    checkJwtExpiration();

    const response = await axios
        .post(PATH.API + "link/invitation/add", {
            guestMail,
            groupName
        }, {
            headers: {
                Authorization: 'Bearer ' + getItem("token")
            }
        })
        .then(() => {
            showNotification({
                title: i18n.t("TitleInvitation"),
                message: i18n.t("MessageInvitation"),
                color: 'green',
            });
        })

    return response;
}

export async function getInvitedEmployee() {
    checkJwtExpiration();

    const response = axios
        .get(PATH.API + "link/invitation/list", {
            headers: {
                Authorization: 'Bearer ' + getItem("token")
            }
        })

    return response;
}