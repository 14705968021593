import { createStyles, Card, Avatar, Text, Group, Button } from '@mantine/core';

const useStyles = createStyles((theme) => ({
    card: {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    },

    avatar: {
        border: `2px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white}`,
    },
}));

interface UserCardImageProps {
    image: string;
    avatar: string;
    name: string;
    fullName?: string;
    groupName?: string;
}



/**
 * Display the card with the user's information
 * @returns {JSX.Element}
 */
export function CardProfile({ image, avatar, name, fullName, groupName }: UserCardImageProps) {
    const { classes, theme } = useStyles();

    const height = image ? 174 : 40;

    return (
        <Card withBorder p="xl" radius="md" className={classes.card} style={{
            
        }}>
            {/*<Card.Section sx={{ backgroundImage: `url(${image})`, height: height, backgroundPosition: "center" }} />
            <Avatar src={avatar} size={80} radius={80} mx="auto" mt={-30} className={classes.avatar} />*/}
            <Text align="center" size="lg" weight={500} >
                {name ? name : fullName}
            </Text>
            <Text align="center" size="sm" weight={400} >
                {groupName}
            </Text>
                {/**
            <Card shadow="sm" radius="md" mt="xl" p="xl">
                 *  <Text size="sm" weight={500} mb="sm">
                    Logs
                </Text>
            </Card>
                 */}
               
        </Card>
    );
}