import { Avatar, Button, Divider, Grid, Group, MultiSelect, Stepper, Text, useMantineTheme } from '@mantine/core';
import React, { forwardRef, useEffect, useRef, useState } from 'react'
import { allPartner, sendMailPartner } from '../../apis/partner.api';
import Editor from '../../components/Editor/Editor';
import { OutputData } from '@editorjs/editorjs';
import { act } from 'react-dom/test-utils';
import { showNotification } from '@mantine/notifications';


type SelectItemPartner = {
    image: string;
    label: string;
    value: string;
    description: string;
}

export default function PartnerPopup(demand: any, openedModalSendMail: boolean, setOpenedModalSendMail: React.FC<any>) {
    const [partners, setPartners]: any = useState([]);
    const demandData: Demand = demand.demand
    const [subject, setSubject] = useState(demandData.title);
    const [partnersSelected, setPartnersSelected] = useState<SelectItemPartner[] | undefined>([])
    const [active, setActive] = useState(0);
    const [highestStepVisited, setHighestStepVisited] = useState(active);
    const [data, setData] = useState<OutputData>(JSON.parse(demandData.name));
    let dataEditorTemp : any = [];


    const nextStep = () => setActive((current) => (current < partners.length ? current + 1 : current));
    const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));

    const handleStepChange = (nextStep: number) => {
        const isOutOfBounds = nextStep > partners.length || nextStep < 0;

        if (isOutOfBounds) {
            return;
        }

        setActive(nextStep);
        setHighestStepVisited((hSC) => Math.max(hSC, nextStep));
    };


    useEffect(() => {
        allPartner().then((res: any) => {

            const data = res.data.data;
            const items: SelectItemPartner[] = [];
            for (const item of data) {
                const selectItem = {
                    image: item.logo,
                    label: item.name,
                    value: item.mail,
                    description: item.name,
                }
                items.push(selectItem);
            }

            setPartners(items);

        }
        ).catch((err) => {
            console.log(err);
        });

        return () => {

        }
    }, [openedModalSendMail])

    const theme = useMantineTheme();
    const ref = useRef<HTMLInputElement>(null);

    const SelectItem = forwardRef<HTMLDivElement, SelectItemPartner>(
        ({ image, label, description, ...others }, ref) => (
            <div ref={ref} {...others}>
                <Group noWrap>
                    <Avatar src={image} />
                    <div >
                        <Text size="sm">{label}</Text>
                        <Text size="xs" opacity={0.65}>
                            {description}
                        </Text>
                    </div>
                </Group>
            </div>
        )
    );
    return (
        <>
            <MultiSelect
                label="Choisir un partenaire"
                itemComponent={SelectItem}
                data={partners}
                onChange={values => {
                    setPartnersSelected(values.map(value => partners.find((item: any) => item.value === value)))
                }}
                searchable
                ref={ref}
                maxDropdownHeight={400}
                nothingFound="Nobody here"
                filter={(value, selected, item): any =>
                    !selected &&
                    (item.label && item.label.toLowerCase().includes(value.toLowerCase().trim()) ||
                        item.description.toLowerCase().includes(value.toLowerCase().trim()))
                }
            />

            <Divider my="sm" />

            <Stepper active={active} onStepClick={setActive} breakpoint="sm" iconSize={24}>
                {partnersSelected?.map((partner: any) => {
                    return (
                        <Stepper.Step key={partner.value} label={partner.description} description="Editer le contenu du mail">
                            <h1>{partner.description}</h1>

                        </Stepper.Step>

                    )
                })}

                {(partnersSelected ?? []).length > 0 ?
                    <Stepper.Completed>
                        Vous pouvez maintenant envoyer
                    </Stepper.Completed> :
                    <p>Veuillez séléctionné un partenaire</p>
                }
            </Stepper>
           

            {partnersSelected?.map((partner: any) => {
                dataEditorTemp.push({id: partner.value, data: partner.data, status: demandData.status})
                const dataEditorFind = dataEditorTemp.find((item: any) => item.id == partner.value);
                    return (
                        <div style={{
                            display: partnersSelected[active] == partner ? "block" : "none"
                        }}>
                            <Editor data={data} onChange={(data: any) => (
                                partner.data = partner.value == dataEditorFind.id ? data : dataEditorFind.data
                            )} 
                            holder={partner.label} />
                        </div>
                        )
                    
                })}
            <Group position="center">

                {active != partnersSelected?.length ? <Button fullWidth mt={"sm"} color="green" disabled={(partnersSelected ?? []).length <= 0} onClick={() => {
                    handleStepChange(active + 1)
                    
                }}>Suivant</Button> : <Button fullWidth mt={"sm"} color="gray" onClick={() => {
                    console.log(dataEditorTemp)
                    dataEditorTemp.map((item: any) => {
                        sendMailPartner(demandData.id, item.status, item.id, item.data)
                        .catch(() => {
                            showNotification({
                                title: `Vous n'avez pas l'autorisation`,
                                message: "Vous n'avez pas l'autorisation",
                                color: 'red',
                            });
                        });
                    })
                }}>Envoyer</Button>}

                
                
            </Group>
        </>
    )
}
