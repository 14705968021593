import React, { useContext, useRef, useState } from 'react'
import { Title, Group, ThemeIcon, Radio,  Switch } from '@mantine/core';
import { PopupButton } from '@typeform/embed-react'
import {  IconPlus } from '@tabler/icons';
import { TableScrollArea } from '../../components/Demands';
import uniBot from '../../assets/MascotteAnimée.gif'
import { demands } from '../../apis/demands.api';
import i18n from '../../i18n';
import AuthContext from '../../contexts/AuthContext';
import { SearchBarDemo } from '../../components/SpotlightSearchBar/SearchBar';
import { getDataResponseId } from '../../apis/typeform.api';
const Dashboard = (props: any) => {
    const [listDemand, setDemands] = useState<Demand[]>([]);
    const [status, setStatus]: any = useState()
    const [sortAscending, setSortAsc] = useState<boolean>(false);
    const appContext = useContext(AuthContext);
    const ref = useRef<HTMLInputElement>(null);
    const addDemand = async (responseId: string) => {
        getDataResponseId(responseId).then(() => {
            setTimeout(() => {
                reqData()
            }, 3000);
        });
    }

    const reqData = async () => {
        let getDatas: any;
        getDatas = await demands()
        setDemands(getDatas.data)
        appContext?.setDemands(getDatas.data)
    }


    return (
        <div>

            <div style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                height: "45px"
            }}>
                <Title order={1} size="2.8em" color={appContext?.company?.companyColor ? appContext?.company?.companyColor : "orange.5"} style={{
                    fontWeight: 600,
                    fontFamily: "Poppins"
                }}>Hello</Title>

                <Title order={1} size="2.8em" color="grey.5" style={{
                    fontWeight: 600,
                    fontFamily: "Poppins"
                }}>{appContext?.firstName} {appContext?.lastName} </Title>
                <img src={uniBot} width="150px" />
            </div>

            <div style={{
                display: "flex",
                alignItems: "center",
                alignContent: "center",
                justifyContent: "space-between",
                paddingRight: "30px"
            }}>


                <SearchBarDemo demands={appContext?.demands} />


                {appContext?.company == null ? <h3>Pas de compagnie</h3> :
                    <PopupButton onSubmit={(e) => {
                        addDemand(e.responseId);

                    }} id="WndnTvM0" style={{
                        background: "none",
                        color: "white",
                        paddingTop: "7px",
                        paddingBottom: "5px",
                        borderRadius: "20px",
                        border: "none",
                        fontFamily: "Poppins",
                    }}>
                        <ThemeIcon radius="xl" size="xl" color={appContext?.company?.companyColor ? appContext.company.companyColor : "orange.5"}>
                            <IconPlus />
                        </ThemeIcon>
                    </PopupButton>
                }
            </div>
            <div style={{
                display: "flex",
                alignItems: "center",
                alignContent: "center",
                gap: "10px",
            }}>
                <Radio.Group
                    name="filterStatus"
                    label="Filtre"
                    onChange={(e) => setStatus(e)}
                    color="orange"
                >
                    {/*<Radio value="En attente de traitement" label={i18n.t("awaitingTreatment")} />*/}
                    <Group mt="xs">
                        <Radio value="Nouveau" label={i18n.t("new")} ref={ref} />
                        <Radio value="Archivé" label={i18n.t("archived")} ref={ref} />
                        <Radio value="Cloturé" label={i18n.t("closed")} ref={ref} />
                        <Radio value="Résolu" label={i18n.t("resolved")} ref={ref} />
                        <Radio value="Ouvert" label={i18n.t("open")} ref={ref} />
                        <Radio value="Provectio Ouvert" label={"Provectio Ouvert"} ref={ref} />
                        <Radio value="Provectio Fermé" label={"Provectio Fermé"} ref={ref} />
                    </Group>
                </Radio.Group>
                <Switch checked={sortAscending} style={{
                    marginTop: "10px",
                }} label="Trier par ordre" onChange={(event) => setSortAsc(event.currentTarget.checked)} size="md" onLabel="ASC" offLabel="DSC"  />
            </div>
            <TableScrollArea filterStatus={status} sortAscending={sortAscending} />

        </div >
    )
}

export default Dashboard