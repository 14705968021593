import { Button, Group, Input, Modal } from '@mantine/core';
import { SpotlightProvider, openSpotlight } from '@mantine/spotlight';
import type { SpotlightAction } from '@mantine/spotlight';
import { IconHome, IconDashboard, IconFileText, IconSearch } from '@tabler/icons';
import i18n from '../../i18n';
import Editor from '../Editor';
import { useState } from 'react';


function SpotlightControl({ openDrawer, data, setOpened, demand }: any) {

    return (
        <>
            <Input
                placeholder={i18n.t("searchRequest")}
                style={{
                    width: "500px"
                }}
                onClick={() => openSpotlight()}
                rightSection={
                    <IconSearch size={18} style={{ display: 'block', opacity: 0.5 }} />
                }
            />
            <Modal
                opened={openDrawer}
                onClose={() => setOpened(false)}
                fullScreen
            >
                <Editor data={data} demand={demand} modalClose={openDrawer} setOpened={setOpened} />

            </Modal>
        </>

    );
}


export function SearchBarDemo(demands: any) {
    let newActions: SpotlightAction[] = [];
    const [opened, setOpened] = useState(false);
    const [data, setData] = useState({})
    const [demand, setDemand] = useState({});
    const listDemands = demands?.demands
    // eslint-disable-next-line array-callback-return
    if (listDemands) {
        listDemands.map(((value: any) => {
            let action = {
                title: value.title,
                description: value.category,
                onTrigger: () => {
                    setOpened(!opened)
                    setData(JSON.parse(value.name))
                    setDemand(value);
                },
                icon: <IconDashboard size={18} />,
            }
            newActions.push(action)
        }))


    }

    return (
        <SpotlightProvider
            actions={newActions}
            searchIcon={<IconSearch size={18} />}
            searchPlaceholder={"Search for a request"}
            shortcut="shift + r"
            nothingFoundMessage="Nothing found..."
        >
            <SpotlightControl demand={demand} data={data} openDrawer={opened} setOpened={setOpened} />
        </SpotlightProvider>
    );
}